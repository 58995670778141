// import {
// 	FiGithub,
// 	FiTwitter,
// 	FiLinkedin,
// 	FiGlobe,
// 	FiYoutube,
// } from 'react-icons/fi';

import { FcGoogle } from "react-icons/fc";
import ath from "../../images/ath-logo.png"
import AppFooterCopyright from './AppFooterCopyright';

const socialLinks = [
	// {
	// 	id: 1,
	// 	icon: <FiGlobe />,
	// 	url: 'https://www.stoman.me/',
	// },
	// {
	// 	id: 2,
	// 	icon: <FiGithub />,
	// 	url: 'https://github.com/realstoman',
	// },
	// {
	// 	id: 3,
	// 	icon: <FiTwitter />,
	// 	url: 'https://twitter.com/realstoman',
	// },
	// {
	// 	id: 4,
	// 	icon: <FiLinkedin />,
	// 	url: 'https://www.linkedin.com/in/realstoman',
	// },
	// {
	// 	id: 5,
	// 	icon: <FiYoutube />,
	// 	url: 'https://www.youtube.com/c/realstoman',
	// },
	{
		id: 6,
		icon: <img src={ath} alt="Annuaire des thérapeutes" style={{ width: '40px', height: '40px' }} />,
		url: "https://www.annuaire-therapeutes.com/therapeute/87722-patricia-delattre-la-ville-du-bois",
	},
	{
		id: 7,
		icon:<FcGoogle />,
		url : "https://www.google.com/maps/place/Patricia+Delattre+Magn%C3%A9tiseur-Rebouteux/@48.6634426,1.2189063,9z/data=!4m7!3m6!1s0x47e5d999ace620b9:0x3079ddfe340fc840!8m2!3d48.6634426!4d2.2735938!15sChtwYXRyaWNpYSBkZWxhdHRyZSByZWJvdXRldXiSASFhbHRlcm5hdGl2ZV9tZWRpY2luZV9wcmFjdGl0aW9uZXLgAQA!16s%2Fg%2F11khzdq_nw?coh=164777&entry=tt&shorturl=1",
	}
];

const AppFooter = () => {
	return (
		<div className="container mx-auto">
			<div className="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark">
				{/* Footer social links */}
				<div className="font-general-regular flex flex-col justify-center items-center mb-12 sm:mb-28">
					<p className="text-3xl sm:text-4xl text-primary-dark dark:text-primary-light mb-5">
						Retrouver moi sur
					</p>
					<ul className="flex gap-4 sm:gap-8">
						{socialLinks.map((link) => (
							<a
								href={link.url}
								target={link.url}
								key={link.id}
								className="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-4 duration-300"
							>
								<i className="text-xl sm:text-2xl md:text-3xl">
									{link.icon}
								</i>
							</a>
						))}
					</ul>
				</div>

				<AppFooterCopyright />
			</div>
		</div>
	);
};

export default AppFooter;
