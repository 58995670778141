import { motion } from 'framer-motion';

import { FiX } from 'react-icons/fi';
import { FiPhone, FiMapPin, FiMail } from 'react-icons/fi';
import Button from './reusable/Button';

// const selectOptions = [
// 	'Web Application',
// 	'Mobile Application',
// 	'UI/UX Design',
// 	'Branding',
// ];

const contacts = [
	{
		id: 1,
		name: (
			<>
				48 <span class="ultra-strong">BIS</span> rue du Grand Noyer, 91620, La Ville du bois
			</>
		),
		icon: <FiMapPin />,
		link: 'https://www.google.com/maps/place/Patricia+Delattre+Magn%C3%A9tiseur-Rebouteux/@48.6634426,1.2189063,9z/data=!4m7!3m6!1s0x47e5d999ace620b9:0x3079ddfe340fc840!8m2!3d48.6634426!4d2.2735938!15sChtwYXRyaWNpYSBkZWxhdHRyZSByZWJvdXRldXiSASFhbHRlcm5hdGl2ZV9tZWRpY2luZV9wcmFjdGl0aW9uZXLgAQA!16s%2Fg%2F11khzdq_nw?coh=164777&entry=tt&shorturl=1',
	},
	// {
	// 	id: 2,
	// 	name: 'email@domain.com',
	// 	icon: <FiMail />,
	// },
	{
		id: 3,
		name: '06 14 90 64 99',
		icon: <FiPhone />,
		link: 'tel:0614906499',
	},
];

const HireMeModal = ({ onClose, onRequest }) => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className="font-general-medium fixed inset-0 z-30 transition-all duration-500"
		>
			{/* Modal Backdrop */}
			<div className="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"></div>

			{/* Modal Content */}
			<main className="flex flex-col items-center justify-center h-full w-full">
				<div className="modal-wrapper flex items-center z-30">
					<div className="modal max-w-md mx-5 xl:max-w-xl lg:max-w-xl md:max-w-xl bg-secondary-light dark:bg-primary-dark max-h-screen shadow-lg flex-row rounded-lg relative">
						<div className="modal-header flex justify-between gap-10 p-5 border-b border-ternary-light dark:border-ternary-dark">
							<h5 className=" text-primary-dark dark:text-primary-light text-xl">
								En quoi puis-js vous aider ?
							</h5>
							{/* <button
								onClick={onClose}
								className="px-4 font-bold text-primary-dark dark:text-primary-light"
							>
								<FiX className="text-3xl" />
							</button> */}
						</div>
						<div className="modal-body p-5 w-full h-full">
							<ul className="font-general-regular">
								{contacts.map((contact) => (
									<li className="flex" key={contact.id}>
										<i className="text-2xl text-gray-500 dark:text-gray-400 mr-4">
											{contact.icon}
										</i>
										<a
											href={contact.link}
											className="text-lg mb-4 text-ternary-dark dark:text-ternary-light"
											target="_blank"
											rel="noopener noreferrer"
										>
											{contact.name}
										</a>
									</li>
								))}
							</ul>
							{/*<form
								onSubmit={(e) => {
									e.preventDefault();
								}}
								className="max-w-xl m-4 text-left"
							>
								<div className="">
									<input
										className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
										id="name"
										name="name"
										type="text"
										required=""
										placeholder="Name"
										aria-label="Name"
									/>
								</div>
								<div className="mt-6">
									<input
										className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
										id="email"
										name="email"
										type="text"
										required=""
										placeholder="Email"
										aria-label="Email"
									/>
								</div>
								<div className="mt-6">
									<select
										className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
										id="subject"
										name="subject"
										type="text"
										required=""
										aria-label="Project Category"
									>
										{selectOptions.map((option) => (
											<option
												className="text-normal sm:text-md"
												key={option}
											>
												{option}
											</option>
										))}
									</select>
								</div>

								<div className="mt-6">
									<textarea
										className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
										id="message"
										name="message"
										cols="14"
										rows="6"
										aria-label="Details"
										placeholder="Project description"
									></textarea>
								</div>

								<div className="mt-6 pb-4 sm:pb-1">
									<span
										onClick={onClose}
										type="submit"
										className="px-4
											sm:px-6
											py-2
											sm:py-2.5
											text-white
											bg-indigo-500
											hover:bg-indigo-600
											rounded-md
											focus:ring-1 focus:ring-indigo-900 duration-500"
										aria-label="Submit Request"
									>
										<Button title="Send Request" />
									</span>
								</div>
							</form> */}
						</div> 
						<div className="modal-footer mt-2 sm:mt-0 py-5 px-8 border0-t text-right">
							<span
								onClick={onClose}
								type="button"
								className="px-4
									sm:px-6
									py-2 bg-gray-600 text-primary-light hover:bg-ternary-dark dark:bg-gray-200 dark:text-secondary-dark dark:hover:bg-primary-light
									rounded-md
									focus:ring-1 focus:ring-indigo-900 duration-500"
								aria-label="Close Modal"
							>
								<Button title="Close" />
							</span> 				
						</div>
					</div>
				</div>
			</main>
		</motion.div>
	);
};

export default HireMeModal;
